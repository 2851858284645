<template lang="pug">
div(
    style="width: 100%; height: auto"
)
    div(
        class="radius"
        v-if="show"
        style="overflow: hidden"
    )
        //- vueSignature(
        //-     ref="signature"
        //-     w="100%"
        //-     h="300px"
        //- )
        MySignaturePad(
            ref="signature"
            w="100%"
            h="300px"
            @onEnd="checkIfSignatureExists"
        )

    div(
        v-else
        class="d-flex align-center justify-center radius"
        style="width: 100%; height: 300px; background-color: white"
    )
        IonSpinner(
            name="crescent"
            color="secondary"
            class="mb-4"
        )

    IonRow(class="receding mb-3")
        //- IonCol(
        //-     size="12"
        //- )
        //-     IonButton(
        //-         @click="save('image/jpeg')"
        //-         expand="block"
        //-         color="secondary"
        //-     )
        //-         IonText(
        //-             color="primary"
        //-         )
        //-             h3 Save
        //- IonCol(
        //-     size="6"
        //- )
        //-     IonButton(
        //-         @click="undo"
        //-         expand="block"
        //-         color="secondary"
        //-     )
        //-         IonText(
        //-             color="primary"
        //-         )
        //-             h3 Undo
        IonCol(
            size="12"
        )
            IonButton(
                @click="clear"
                expand="block"
                color="secondary"
            )
                IonText(
                    color="primary"
                )
                    h3 Unterschrift Löschen
</template>

<script>
import { reactive, ref } from "vue"
import vueSignature from "vue-signature"
import MySignaturePad from "@/components/molecules/MySignaturePad"

export default {
    components: {
        vueSignature,
        MySignaturePad
    },
    setup(_, { emit }) {
        const signature = ref(null)

        const show = ref(false)

        emit("moduleLoading")

        setTimeout(() => {
            show.value = true
            emit("moduleReady")
        }, 1000)

        function checkIfSignatureExists() {
            let exists = !signature.value.isEmpty()
            emit("updateSignatureExists", exists)
        }

        const save = (t) => {
            var jpeg = signature.value.save("image/jpeg")
            // console.log("sig", signature.value.isEmpty(), signature.value.sig)
        }

        const clear = () => {
            signature.value.clear()
            checkIfSignatureExists()
        }

        const undo = () => {
            signature.value.undo()
            checkIfSignatureExists()
        }

        return { signature, save, clear, undo, show, checkIfSignatureExists }
    }
}
</script>